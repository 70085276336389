export const EXAMPLE_NEWS = [
    {
      title: "Новина 1",
      body: "Текст новини 1",
      createdAt: "2021-06-01T00:00:00.000Z",
    },
    {
      title: "Новина 2",
      body: "Текст новини 2",
      createdAt: "2022-06-02T00:00:00.000Z",
    },
    {
      title: "Новина 3",
      body: "Текст новини 3",
      createdAt: "2023-06-03T00:00:00.000Z",
    },
  ]